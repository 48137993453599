<!-- 
    该组件仅用来全局覆盖element-plus中的样式
 -->

<template>
    <div></div>
</template>
<script lang="ts" setup>
</script>
<style lang="scss" scope>
// 重写element-plus中组件的样式

// Tabs组件的样式
.el-tabs__item:hover {
    color: #ffa34c;
}

.el-tabs__item.is-active {
    color: #FC8F28;
}

.el-tabs__active-bar {
    background-color: #FC8F28;
}

// 输入框style
.el-input{
    --el-input-focus-border-color: #FC8F28;
}
// 按钮style
.el-button{
    --el-button-outline-color:#FC8F28;
}
</style>
  