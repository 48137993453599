import { createRouter, createWebHashHistory } from "vue-router";
import { contentRoutes } from "./routers";
import { ElMessage } from "element-plus";


const router = createRouter({ // 切换页面后新的页面保持在顶端展示
  history: createWebHashHistory(),
  routes:contentRoutes,
  scrollBehavior(): any {
    return { top: 0 };
  },
});


router.beforeEach(async (to, from) => { //导航守卫、路由守卫、路由拦截
  if (
    !localStorage.getItem("token") &&
    (to.name == "PersonalCenter" ||
      to.name == "MyQuotation" ||
      to.name == "UserInfo")
  ) {
    ElMessage.error("用户登录过期，请先登录！");
    return { name: "Login" };
  }
});

export default router;
