import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import './mock/index' // mock拦截器
import "bootstrap-icons/font/bootstrap-icons.css"; // bootstrap-icons
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入自定义插件对象：注册整个项目全局组件
import gloalComponent from '@/components'

const app = createApp(App)

// 安装自定义插件
app.use(gloalComponent)
app.use(ElementPlus)
app.use(router)

app.mount('#app')


