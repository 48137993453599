import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { ElMessage } from "element-plus";
import router from '@/router';

const axiosInstance: AxiosInstance = axios.create({
  // 可通过他操作接口域名
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL:"/mock",
  timeout: 20000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS',
  },

});



// 添加请求拦截器
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.headers = config.headers || {};
    config.headers.Authorization = 'Bearer ' + localStorage.token;
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
// 添加响应拦截
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: any) => {
    let message = '';
    const status = error.response?.status;
    switch (status) {
      case 401:
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        message = '用户登录过期，请重新登录！';
        router.push('/login');
        break;
      case 403:
        message = '无权访问';
        break;
      case 404:
        message = '网络请求地址错误';
        break;
      case 500:
        message = '服务器出现错误';
        break;
      default:
        break;
    }
    ElMessage.error(message);
    return Promise.reject(error);
  }
);

export default axiosInstance;
