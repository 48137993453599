// 热门目的地国家图片和名称
interface Nation {
  id:number;
  cname: string;
  image1: string;
}
interface countrys {
  id: number;
  cname: string;
}
// 热门目的地州名和国家名
interface Destination {
  state: string;
  countrys: countrys[];
}
// 热门景点
interface Attractions {
  id: number;
  image1: string;
  cname: string;
  address: string;
  cbrief: string;
}
// 热门路线
import { JourneyPrimary } from "./ClassicTravelRoute";
// 特色图片和文字
interface feature {
  imgUrl: string;
  text1: string;
  text2: string;
  text3: string;
}
interface headerImg {
  path: string;
}
// 当地玩乐信息
export interface Attraction {
  id: number;
  cname: string; // 当地玩乐名称
  address: string; // 景点地址
  cbrief: string; // 当地玩乐内容
  image1: string; // 当地玩乐图片
}
// Inidata
export class Initdata {
  DestinationInfo: Array<Destination> = [];
  Nations: Array<Nation> = [];
  Attractions: Array<Attractions> = [];
  routes: Array<JourneyPrimary> = [];

  //#region 静态数据
  // 特色图片和文字的数据
  feature: Array<feature> = [
    {
      imgUrl: require("@/assets/home/Slice410.png"),
      text1: "亲测靠谱",
      text2: "只有我们体验过的",
      text3: "才会推荐给您",
    },
    {
      imgUrl: require("@/assets/home/Slice4213.png"),
      text1: "精致高端",
      text2: "心无旁骛",
      text3: "专注精致高端旅行",
    },
    {
      imgUrl: require("@/assets/home/Slice429.png"),
      text1: "匠心缔造",
      text2: "匠心打磨",
      text3: "用恰当的方式体验当地精华",
    },
    {
      imgUrl: require("@/assets/home/Slice4211.png"),
      text1: "主题定制",
      text2: "旅行就该个性喷张",
      text3: "卜卡为您私人定制",
    },
    {
      imgUrl: require("@/assets/home/Slice4212.png"),
      text1: "全程服务",
      text2: "不止关注预定",
      text3: "更为您的行程全程负责",
    },
  ];
  // 头图
  headerImgs: Array<headerImg> = [
    { path: require("@/assets/home/01.jpg") },
    { path: require("@/assets/home/02.jpg") },
    { path: require("@/assets/home/03.jpg") },
    { path: require("@/assets/home/04.jpg") },
  ];
  //#endregion
}
