<!-- 全局组件使用说明：
面包屑导航，与 NavBreadcrumbItem 组件一起使用
用例：
 <NavBreadcrumb>
      <NavBreadcrumbItem :to="'/'">首页</NavBreadcrumbItem>
      <NavBreadcrumbItem :toNum="-1">目的地</NavBreadcrumbItem>
      <NavBreadcrumbItem :isLastItem="true">{{ routerData?.croutetitle }}</NavBreadcrumbItem>
 </NavBreadcrumb>
 属性介绍：
 to：代表跳转的路由地址，使用 to 则不使用 toNum ，反之 使用了 toNum 则不使用 to
 toNum：使用了 useRouter 对象的 go方法 toNum 的值就是 router.go(toNum) 中 go() 的参数,，router.go()的使用方式可参照vue-router官方文档
        import { useRouter } from 'vue-router';
        const router = useRouter();
        router.go(toNum)
 isLastItem：是否是最后一个导航项，默认为false，当为true时，代表时面包屑导航的最后一个导航项，最后一个导航项的后面不会显示箭头
-->
<template>
    <nav class="breadcrumb">
        <slot></slot>
    </nav>
</template>

<script setup lang="ts">

</script>

<style scoped>
.breadcrumb {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    height: 55px;
    line-height: 55px;
    width: 1100px;
    margin: 0 auto;
    user-select: none;
    display: flex;
    align-items: center;

}
</style>