import HomeView from "@/views/ContentPages/HomeView.vue";
import { RouteRecordRaw } from "vue-router";

export const contentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/navigation",
    name: "Navigation",
    component: () =>
      import(/* webpackChunkName: "navigation" */ "../views/Navigation.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "/journeyCustom",
        name: "JourneyCustom",
        component: () =>
          import(
              /* webpackChunkName: "JourneyCustom" */ "../views/ContentPages/JourneyCustom.vue"
          ),
      },
      {
        path: "/classicTravelRoute",
        name: "ClassicTravelRoute",
        component: () =>
          import(
              /* webpackChunkName: "ClassicTravelRoute" */ "../views/ContentPages/ClassicTravelRoute.vue"
          ),
      },
      {
        path: "/journeyDetail",
        name: "JourneyDetail",
        component: () =>
          import(
              /* webpackChunkName: "JourneyDetail" */ "../views/ContentPages/JourneyDetail.vue"
          ),
      },
      {
        path: "/journeyDestination",
        name: "JourneyDestination",
        component: () =>
          import(
              /* webpackChunkName: "RouteCreate" */ "../views/ContentPages/JourneyDestination.vue"
          ),
      },
      {
        path: "/productQuotation",
        name: "ProductQuotation",
        component: () =>
          import(
              /* webpackChunkName: "ProductQuotation" */ "../views/ContentPages/ProductQuotation.vue"
          ),
      },
      {
        path: "/automaticCustomizedTravel",
        name: "AutomaticCustomizedTravel",
        component: () =>
          import(
              /* webpackChunkName: "AutomaticCustomizedTravel" */ "../views/ContentPages/AutomaticCustomizedTravel.vue"
          ),
      },
      {
        path: "/mainBillDetail",
        name: "MainBillDetail",
        component: () =>
          import(
              /* webpackChunkName: "mainBillDetail" */ "../views/ContentPages/MainBillDetail.vue"
          ),
      },
      {
        path: "/personalCenter",
        name: "PersonalCenter",
        component: () =>
          import(
              /* webpackChunkName: "PersonalCenter" */ "../views/PersonalCenter.vue"
          ),
        children: [
          {
            path: "/myQuotation",
            name: "MyQuotation",
            component: () =>
              import(
                  /* webpackChunkName: "MyQuotation" */ "../views/PersonalCenter/MyQuotation.vue"
              ),
          },
          {
            path: "/userInfo",
            name: "UserInfo",
            component: () =>
              import(
                  /* webpackChunkName: "UserInfo" */ "../views/PersonalCenter/UserInfo.vue"
              ),
          },
        ],
      },
      {
        path: "/footerLayout",
        name: "FooterLayout",
        component: () =>
          import(
              /* webpackChunkName: "FooterLayout" */ "../views/FooterLayout.vue"
          ),
        children: [
          {
            path: "/aboutUs",
            name: "AboutUs",
            component: () =>
              import(
                  /* webpackChunkName: "AboutUs" */ "../views/FooterLayout/AboutUs.vue"
              ),
          },
          {
            path: "/brandStory",
            name: "BrandStory",
            component: () =>
              import(
                  /* webpackChunkName: "BrandStory" */ "../views/FooterLayout/BrandStory.vue"
              ),
          },
          {
            path: "/contactUs",
            name: "ContactUs",
            component: () =>
              import(
                  /* webpackChunkName: "ContactUs" */ "../views/FooterLayout/ContactUs.vue"
              ),
          },
          {
            path: "/ourPartners",
            name: "OurPartners",
            component: () =>
              import(
                  /* webpackChunkName: "OurPartners" */ "../views/FooterLayout/OurPartners.vue"
              ),
          },
          {
            path: "/enterpriseQualification",
            name: "EnterpriseQualification",
            component: () =>
              import(
                  /* webpackChunkName: "EnterpriseQualification" */ "../views/FooterLayout/EnterpriseQualification.vue"
              ),
          },
        ],
      },

    ],
  },
  {
    path: "/h5Detail",
    name: "H5Detail",
    component: () =>
      import(
          "../views/ContentPages/H5Detail.vue"
      ),
  },
  {
    path: "/PackageInfo",
    name: "PackageInfo",
    component: () =>
      import(
          "../views/ContentPages/PackageInfo.vue"
      ),
  },
  {
    path: "/H5Bill",
    name: "H5Bill",
    component: () =>
      import(
          "../views/ContentPages/H5Bill.vue"
      ),
  },
  {
    path: "/:cathAll(.*)",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
          /* webpackChunkName: "login" */ "../views/UserManagement/Login.vue"
      ),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
          /* webpackChunkName: "register" */ "../views/UserManagement/Register.vue"
      ),
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: () =>
      import(
          /* webpackChunkName: "changePassword" */ "../views/UserManagement/ChangePassword.vue"
      ),
  },
  {
    path: "/resetPasswords",
    name: "ResetPasswords",
    component: () =>
      import(
          /* webpackChunkName: "resetPasswords" */ "../views/UserManagement/ResetPasswords.vue"
      ),
  },
  {
    path: "/CustomizeTourH5",
    name: "CustomizeTourH5",
    component: () =>
      import(
          /* webpackChunkName: "resetPasswords" */ "../views/ContentPages/CustomizeTourH5.vue"
      ),
  },

];