// 引入项目中全部的全局组件
import DialogBox from './DialogBox/DialogBox.vue';
import DialogBoxSight from './DialogBox/DialogBoxSight.vue';
import BackTop from './Navigation/BackTop.vue';
import NavBreadcrumb from './NavBreadcrumb/NavBreadcrumb.vue';
import NavBreadcrumbItem from './NavBreadcrumb/NavBreadcrumbItem.vue';
import NavigationBars from './Navigation/NavigationBars.vue';
import PagingBar from './Navigation/PagingBar.vue';
import LoadingMask from './Loading/LoadingMask.vue';
import StartCountDown from './Loading/StartCountDown.vue';
import CoverStyle from './CoverStyle/CoverStyle.vue';
import CoverElButton from './CoverElButton/CoverElButton.vue';


// 引入element-plus提供全部图标组件
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 全局对象
const allGloablComponent: any = {
    BackTop, DialogBox, DialogBoxSight, LoadingMask, NavBreadcrumb, NavBreadcrumbItem, NavigationBars, PagingBar,
    StartCountDown, CoverStyle, CoverElButton
}

// 对外暴露插件对象
export default {
    // 务必叫 install 方法
    install(app: any) {
        Object.keys(allGloablComponent).forEach(key => {
            app.component(key, allGloablComponent[key]); // 注册所有组件
        })
        // 将element-plus提供图标注册为全局组件
        for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
            app.component(key, component)
        }
    }
}